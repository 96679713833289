import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import instance from "../../Interceptor/api_instance";
import { AdvancedSearchCtpat } from "../Utility/AdvancedSearch";
import {
  Spinner,
} from "../LoadingAnimations/LoadingAnimations";
import SearchBar from "../Inputs/SearchBar";
import { DownArrow, UpArrow } from "../../Media/Icons";
import { convertToPST } from "../../Helper/FormatFunctions";
import { Export15Forms } from "../Modals/PrintF15Forms";
import { SpinnerOverlay } from "../LoadingAnimations/SpinnerOverlay";
import { RowClickF15Modal } from "../Modals/EditF15Modal";
export const BacklogCTPAT = () => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openSearchForm, setOpenSearchForm] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [url, setUrl] = useState("safety/ctpat-reports");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [downloading, setDownloading] = useState(false);
  const [modalRow, setModalRow] = useState(null);

  const isAllSelected =
    selectedRows.length === data?.results?.length && data?.results?.length > 0;
  const isIndeterminate =
    selectedRows.length > 0 && selectedRows.length < data?.results?.length;

  const headers = [
    t("yard-tables.table-columns.truck"),
    t("yard-tables.table-columns.trailer"),
    t("yard-tables.table-columns.datetime"),
    t("yard-tables.table-columns.inorout"),
    t("yard-tables.table-columns.guard"),
    t("yard-tables.table-columns.driver"),
  ];

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response = await instance.get(url);
      if (response.status === 200) {
        setData(response.data);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [url]);

  const handlePageNav = (next) => {
    if (isLoading) return;
    if (next) {
      setCurrentPageNum(currentPageNum + 1);
      setUrl(data?.next);
    } else {
      setCurrentPageNum(currentPageNum - 1);
      setUrl(data?.previous);
    }
  };

  useEffect(() => {
    const queryString = new URLSearchParams();
    if (fromDate !== "") {
      queryString.append("fromdate", fromDate);
    }
    if (toDate !== "") {
      queryString.append("todate", toDate);
    }
    setCurrentPageNum(1);
    setUrl(`safety/ctpat-reports?${queryString}`);
  }, [fromDate, toDate]);

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      // Select all rows
      setSelectedRows(data.results);
    } else {
      setSelectedRows([]);
    }
  };

  const selectCheckbox = (e, item) => {
    const isChecked = e.target.checked;

    setSelectedRows((prevSelectedRows) => {
      if (isChecked) {
        // Add the item to the selectedRows array
        return [...prevSelectedRows, item];
      } else {
        // Remove the item from the selectedRows array
        return prevSelectedRows.filter((row) => row.id !== item.id);
      }
    });
  };

  const handleOpenModal = (item) => {
    setModalRow(item);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalRow(null);
  }

  return (
    <div className="global-cont">
      <Helmet>
        <title>Forward | {t("page-headers.reports")}</title>
      </Helmet>
      {downloading && <SpinnerOverlay />}
      <div className="page-overview">
        <div className="cont-wrapper">
          <div className="overview-header">
            <h1 className="global-h1 black">
              C-TPAT {t("page-headers.reports")}
            </h1>
          </div>
          {error && <p className="error">{error}</p>}
          {isModalOpen && (
            <div className="page-mask page-mask-animation">
              <div className="modal-cont2"><RowClickF15Modal report={modalRow} close={closeModal} english={t}/></div>
            </div>
          )}
          {openSearchForm && <div className="page-mask page-mask-animation">
              <div className="modal-cont2"><AdvancedSearchCtpat/></div>
            </div>}
          {isLoading ? (
            <SpinnerOverlay />
          ) : (
            <div className="search-filters-cont">
              <form id="filter-form">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="search-filters">
                    <SearchBar />
                    <div style={{ display: "flex", gap: "12px" }}>
                      <input
                        type="date"
                        className="date-input-filter"
                        onChange={(e) => setFromDate(e.target.value)}
                        value={fromDate}
                      />
                      <input
                        type="date"
                        className="date-input-filter"
                        onChange={(e) => setToDate(e.target.value)}
                        value={toDate}
                      />
                      {/* <button className="change-pwd-btn" type="button" onClick={() => setOpenSearchForm(true)}>
                        {t("forms.type.advanced-search.header")}
                      </button> */}
                      <button
                        className="all-btn success-btn"
                        type="button"
                        onClick={() =>
                          Export15Forms(selectedRows, setDownloading)
                        }
                      >
                        {t("buttons.print")}
                      </button>
                    </div>
                  </div>
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    <div className="page-select">
                      {!isLoading && data?.previous && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          onClick={
                            !isLoading ? () => handlePageNav(false) : null
                          }
                        >
                          <path
                            d="M10.5601 15.04L4.16006 8.64C4.00006 8.48 3.92006 8.24 3.92006 8C3.92006 7.76 4.00006 7.52 4.16006 7.36L10.5601 0.960004C10.9601 0.560004 11.5201 0.560004 11.9201 0.960004C12.3201 1.36 12.3201 1.92 11.9201 2.32L6.16006 8.08L11.9201 13.84C12.3201 14.24 12.3201 14.8 11.9201 15.2C11.5201 15.44 10.8801 15.44 10.5601 15.04Z"
                            fill="#5656B9"
                          />
                        </svg>
                      )}{" "}
                      <span>{currentPageNum}</span>
                      {!isLoading && data?.next && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          onClick={
                            !isLoading ? () => handlePageNav(true) : null
                          }
                        >
                          <path
                            d="M5.44009 0.959997L11.8401 7.36C12.0001 7.52 12.0801 7.76 12.0801 8C12.0801 8.24 12.0001 8.48 11.8401 8.64L5.44009 15.04C5.04009 15.44 4.48009 15.44 4.08009 15.04C3.68009 14.64 3.68009 14.08 4.08009 13.68L9.84009 7.92L4.08009 2.16C3.68009 1.76 3.68009 1.2 4.08009 0.799996C4.48009 0.559997 5.12009 0.559998 5.44009 0.959997Z"
                            fill="#5656B9"
                          />
                        </svg>
                      )}
                    </div>
                  )}
                </div>
              </form>
            </div>
          )}
          {data && !isLoading ? (
            <>
              <div className="table-cont">
                <table id="forwardTable" className="yard-table">
                  <thead>
                    <tr className="table-header">
                      <th scope="col">
                        <input
                          type="checkbox"
                          onChange={handleSelectAll}
                          checked={isAllSelected}
                          ref={(el) => {
                            if (el) el.indeterminate = isIndeterminate;
                          }} // Handle the indeterminate state
                        />
                      </th>
                      {headers?.map((item, index) => (
                        <th key={index} scope="col">
                          <button className="col-header">
                            {item}
                            <div className="order-filter">
                              <UpArrow />
                              <DownArrow />
                            </div>
                          </button>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data.results?.map((item, index) => (
                      <tr
                        key={index}
                        className="data-rows row-hover"
                        data-testid={`row-${item.id}`}
                      >
                        <td data-label="check" key={index}>
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              selectCheckbox(e, item);
                            }}
                            checked={selectedRows.some(
                              (selected) => selected.id === item.id
                            )}
                          />
                        </td>
                        <td
                          data-label="truck"
                          key={`${index}-truck`}
                          className="gray"
                          onClick={() => handleOpenModal(item)}
                        >
                          {item.truck.carrier_name}-{item.truck.truck_number}
                        </td>
                        <td
                          data-label="trailer"
                          key={`${index}-trailer`}
                          className="gray"
                        >
                          {item.trailer
                            ? item.trailer.trailer_number
                            : t("forms.type.vehicle.placeholders.none")}
                        </td>
                        <td
                          data-label="datetime"
                          key={`${index}-datetime`}
                          className="gray"
                        >
                          {item.check_in_dt
                            ? convertToPST(item.check_in_dt)
                            : convertToPST(item.check_out_dt)}
                        </td>
                        <td
                          data-label="inorout"
                          key={`${index}-inout`}
                          className="gray"
                        >
                          {item.check_in_dt ? "In" : "Out"}
                        </td>
                        <td
                          data-label="guard"
                          key={`${index}-guard`}
                          className="gray"
                        >
                          {item.user.first_name} {item.user.last_name}
                        </td>
                        <td
                          data-label="driver"
                          key={`${index}-driver`}
                          className="gray"
                        >
                          {item.visitor.first_name} {item.visitor.last_name}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};
