import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import instance from "../../Interceptor/api_instance";
import { useAuth } from "../../Auth/AuthProvider";
import { Helmet } from "react-helmet";
import MechanicMaintenanceReports from "../Tables/MechanicMaintenanceReports";

export const MechanicReportsView = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [activityData, setActivityData] = useState([]);

  return (
    <div className="global-cont">
      <Helmet>
        <title>Forward | {t("page-headers.reports")}</title>
      </Helmet>
      <div className="page-overview">
        <div className="cont-wrapper">
          <div className="inventory-header">
            <h2 className="light-black global-h2">
              {t("page-headers.reports")}
            </h2>
          </div>
          <MechanicMaintenanceReports />
        </div>
      </div>
    </div>
  );
};
