import { PDFDocument } from "pdf-lib";
import { formatReadableDate, formatReadableTime } from "../Modals/FormatFunctions";

export const populateF15 = async (templateArrayBuffer, report) => {
  const pdfDoc = await PDFDocument.load(templateArrayBuffer);
  let form = pdfDoc.getForm();

  // Check if this is an entry or exit report
  let origin_type = null;
  if (report.check_in_dt) {
    origin_type = true;
    form.getCheckBox("checkbox_entry").check();
    form
      .getTextField("entry_date")
      .setText(formatReadableDate(report.check_in_dt));
    form
      .getTextField("entry_time")
      .setText(formatReadableTime(report.check_in_dt));
  } else if (report.check_out_dt) {
    form.getCheckBox("checkbox_exit").check();
    origin_type = false;
    form
      .getTextField("entry_date")
      .setText(formatReadableDate(report.check_out_dt));
    form
      .getTextField("entry_time")
      .setText(formatReadableTime(report.check_out_dt));
  } else {
    throw new Error(
      `Report id ${report.id} has no check in nor check out datetime.`
    );
  }

  const truck = report.truck;
  form.getTextField("carrier_name").setText(truck.carrier_name);
  form.getTextField("truck_number").setText(truck.truck_number);
  form.getTextField("truck_plates").setText(truck.truck_plate);
  form
    .getTextField("driver_name")
    .setText(`${report.visitor.first_name} ${report.visitor.last_name}`);
  form
    .getTextField("destination")
    .setText(origin_type ? truck.origin : truck.destination);

  // Finish filling the inspections points for the truck

  const f15_truck = report.f15_truck;
  const truck_inspection_points = f15_truck.truck_inspection;

  let pests_free = truck_inspection_points.pests.value;
  let garbage_free = truck_inspection_points.garbage.value;
  let clean = truck_inspection_points.cleanliness.value;

  for (const key in truck_inspection_points) {
    form = FillInspectionPoints(form, truck_inspection_points, key);
  }

  // Dynamically fill input fields for trailer if it exists for entry or exit
  if (report.f15_trailer && report.trailer) {
    const trailer = report.trailer;
    form.getTextField("trailer_number").setText(trailer.trailer_number);
    form.getTextField("entry_trailer_plates").setText(trailer.license_plate);
    form
      .getTextField("trailer_isloaded")
      .setText(trailer.is_loaded ? "Cargada" : "Vacia");
    form
      .getTextField("entry_seal_number")
      .setText(trailer.seal_number ? trailer.seal_number : "");

    // Fill inspection points for trailer
    const f15_trailer = report.f15_trailer;
    const trailer_inspection_data = f15_trailer.trailer_inspection;
    for (const key in trailer_inspection_data) {
      form = FillInspectionPoints(form, trailer_inspection_data, key);
    }

    // Fill seal verification checkboxes
    const seal_tests = f15_trailer.seal_tests;
    for (const key in seal_tests) {
      form.getCheckBox(key).check(seal_tests[key]);
    }
    const trailerDimensions = trailer.trailer_dimensions;

    // Compare contamination booleans using trailer values now
    pests_free = pests_free && trailer_inspection_data.pests.value;
    garbage_free = garbage_free && trailer_inspection_data.garbage.value;
    clean = clean && trailer_inspection_data.cleanliness.value;

    form
      .getTextField("anomalies")
      .setText(f15_trailer.anomalies ? f15_trailer.anomalies : "");
    // Check off the standard measurement of the trailer
    form
      .getCheckBox(`check_box_${trailerDimensions.standard.toString()}`)
      .check();
  }

  // Agriculture inspection points (checkboxes)
  if (pests_free) {
    form.getCheckBox("plagas_no").check();
  } else {
    form.getCheckBox("plagas_si").check();
  }

  if (clean) {
    form.getCheckBox("sucia_no").check();
  } else {
    form.getCheckBox("sucia_si").check();
  }
  if (garbage_free) {
    form.getCheckBox("basura_no").check();
  } else {
    form.getCheckBox("basura_si").check();
  }

  // Any anomalies reported
  const anomalies_field = form.getTextField("anomalies").getText();
  form
    .getTextField("anomalies")
    .setText(
      `${anomalies_field ? anomalies_field : ""} ${
        f15_truck.anomalies ? f15_truck.anomalies : ""
      }`
    );

  form.getTextField("guard_name").setText(f15_truck.guard_name);
  return await pdfDoc.save();
};

// Function to set text fields based on boolean value
function setTextFromBoolean(value) {
  if (value === null) {
    return "N/A";
  } else {
    return value ? "OK" : "Mal";
  }
}

function FillInspectionPoints(form, data, key) {
  const pest_and_garbage = new Set(["garbage", "pests"]);

  // These points are shared by trailer and truck
  const shared_points = new Set(["tires", "chassis", "ceiling", "cleanliness"]);

  if (shared_points.has(key)) {
    // Get current status of the point to see if it has already been populated
    let current_status = form.getTextField(key).getText();
    let current_status_notes = form.getTextField(`${key}_notes`).getText();

    // If status is not checked off as poor then populate it
    // This will ensure regardless, the status is populated or kept as poor always
    if (current_status !== "Mal") {
      form.getTextField(key).setText(setTextFromBoolean(data[key].value));
    }

    // All notes will be recorded regardless of point status
    form
      .getTextField(`${key}_notes`)
      .setText(
        `${current_status_notes ? current_status_notes : ""} ${data[key].notes}`
      );
  } else if (pest_and_garbage.has(key)) {
    let current_status = form.getTextField("garbage").getText();
    let current_status_notes = form.getTextField("garbage_notes").getText();
    if (current_status !== "Mal") {
      form.getTextField("garbage").setText(setTextFromBoolean(data[key].value));
    }

    form
      .getTextField("garbage_notes")
      .setText(
        `${current_status_notes ? current_status_notes : ""} ${data[key].notes}`
      ); // Condition for current_status_notes if it is not undefined
  } else {
    form.getTextField(key).setText(setTextFromBoolean(data[key].value));
    form.getTextField(`${key}_notes`).setText(data[key].notes);
  }
  return form;
}
export const loadPdfTemplate = async (pdfFile) => {
    const response = await fetch(`./${pdfFile}`);
    const arrayBuffer = await response.arrayBuffer();
    return arrayBuffer;
  };