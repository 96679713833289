export const SpinnerOverlay = () => (
    <div style={overlayStyle}>
      <div style={spinnerStyle}></div>
    </div>
  );
  
  const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000, // Ensure it covers everything
  };
  
  const spinnerStyle = {
    width: "50px",
    height: "50px",
    border: "5px solid #f3f3f3",
    borderTop: "5px solid #5656B9", // Spinner color
    borderRadius: "50%",
    animation: "spin 1s linear infinite",
  };
  