export const CheckInTrailerF15 = ({
  backForm,
  nextForm,
  english,
  handleTrailerChange,
  trailerForm,
  sealStatus,
  inOrOut
}) => {
  const isAnyFieldPoor = () => {
    return Object.values(trailerForm).some((value) => value === false);
  };

    return (
        <div className="questions-cont">
            <h2 className="global-h2 light-black">{inOrOut ? english("forms.type.vehicle.check-in.header") : english("forms.type.vehicle.check-out.header")}</h2>
            <h4 className="global-h4 gray bottom-space">
            {english("forms.type.f15.trailer-subheader")}
            </h4>
            <form onSubmit={nextForm} id="form-trailerF15">
            {Object.keys(trailerForm.trailer_inspection).map((key) => (
          (key != "seal_tests" && key != "trailer_anomalies") && (<div className="form-group">
            <label className="input-label required">{english(`forms.type.f15.trailer.labels.${key}`)}</label>
            <div className="input-cont">
              <fieldset className="fieldsets">
                <div className="radio-options">
                  <input
                    type="radio"
                    name={key}
                    checked={trailerForm.trailer_inspection[key].value}
                    onClick={() => handleTrailerChange(key, "value", true)}
                    className="radio-input"
                    required
                  />{" "}
                  <label>{english("forms.type.vehicle.placeholders.select-condition.good")}</label>
                </div>
                <div className="radio-options">
                  <input
                    type="radio"
                    name={key}
                    checked={trailerForm.trailer_inspection[key].value === false}
                    onClick={() => handleTrailerChange(key, "value", false)}
                    className="radio-input"
                  />
                  <label>{english("forms.type.vehicle.placeholders.select-condition.poor")}</label>
                </div>
                {(key == "refrigerator" || key == "sealCondition") && (
                    <div className="radio-options">
                    <input
                      type="radio"
                      name={key}
                      checked={trailerForm.trailer_inspection[key].value === null}
                      onClick={() => handleTrailerChange(key, "value", null)}
                      className="radio-input"
                    />
                    <label>N/A</label>
                  </div>
                )}
              </fieldset>
              <input
              className="text-input"
              type="text"
              placeholder={english("forms.type.vehicle.input-headers.notes")}
              value={trailerForm.trailer_inspection[key].notes}
              onChange={(e) => handleTrailerChange(key, "notes", e.target.value)}
              required={trailerForm.trailer_inspection[key].value === false}
            />
            </div>
          </div>)
        ))}
                { sealStatus === 'true' || sealStatus === true ? <div className="form-group">
                    <label className="input-label required">{english ? "Seal tests " : "Pruebas de sello"}</label>
                    <div className="input-cont">
                        <div className="checkboxes-cont">
                            <div className="check-cont">
                                <input type="checkbox" onChange={e => handleTrailerChange("seal_tests", 'view_seal', e.target.checked)} checked={trailerForm.seal_tests.view_seal} required/>
                                <label>{english("forms.type.f15.trailer.labels.view_seal")}</label>
                            </div>
                            <div className="check-cont">
                                <input type="checkbox" onChange={e => handleTrailerChange("seal_tests", 'verify_seal', e.target.checked)} checked={trailerForm.seal_tests.verify_seal} required/>
                                <label>{english("forms.type.f15.trailer.labels.verify_seal")}</label>
                            </div>
                            <div className="check-cont">
                                <input type="checkbox" required onChange={e => handleTrailerChange("seal_tests", 'tug_on_seal', e.target.checked)} checked={trailerForm.seal_tests.tug_on_seal}/>
                                <label>{english("forms.type.f15.trailer.labels.tug_on_seal")}</label>
                            </div>
                            <div className="check-cont">
                                <input type="checkbox" required onChange={e => handleTrailerChange("seal_tests", 'twistTurn_seal', e.target.checked)} checked={trailerForm.seal_tests.twistTurn_seal}/>
                                <label>{english("forms.type.f15.trailer.labels.twistTurn_seal")}</label>
                            </div>
                        </div>
                    </div>
                </div> : null}
                <div className="form-group">
                    <label className="input-label">{english("forms.type.f15.truck.labels.anomalies")}</label>
                    <div className="input-cont">
                        <input className="text-input" placeholder={english("forms.type.f15.truck.labels.anomalies")} type="text" onChange={e => handleTrailerChange("anomalies", 'anomalies', e.target.value)} value={trailerForm.anomalies} required={isAnyFieldPoor()}/>
                    </div>
                </div>
                <div className="full-cont">
                    <button className="change-pwd-btn" type="submit">Continue</button>
                    <button className="cancel-btn" onClick={backForm}>Back</button>
                </div>
            </form>
        </div>
    )
}