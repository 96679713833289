import { useEffect, useState } from "react";
import instance from "../../../Interceptor/api_instance";
import { CheckIn1stForm } from "./CheckinCheckout";
import { CheckInTruckF15 } from "./CheckInTruckF15";
import { CheckInTrailerF15 } from "./CheckInTrailerF15";
import { CheckInPhotos } from "./CheckInPhotos";
import { CheckInSuccess } from "./CheckInSuccess";
import CheckoutConfirm from "../../Modals/CheckoutConfirm";

export const CheckInVehicle = ({ onClose, english }) => {
  // Check in conditions
  const [carrying, setCarrying] = useState(null);
  const [trailerType, setTrailerType] = useState(null);
  const [atiBool, setBool] = useState(false);
  const [country, setCountry] = useState("");
  const [countryState, setState] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [pinNum, setPinNum] = useState(null);

  // Trailer states
  const [trailerList, setTrailerList] = useState([]);
  const [customer, setCustomer] = useState("");
  const [customerList, setCustomerList] = useState([]);
  const [trailerBody, setTrailerBody] = useState({});
  const [dimensionOption, setDimensionOption] = useState([]);
  const [customLength, setCustomLength] = useState("");
  const [customWidth, setCustomWidth] = useState("");
  const [customHeight, setCustomHeight] = useState("");

  const [generalNotes, setGeneralNotes] = useState("");

  // Truck states
  const [carrier, setCarrier] = useState("");
  const [otherCarrier, setOtherCarrier] = useState("");
  const [truckList, setTruckList] = useState([]);
  const [truckBody, setTruckBody] = useState({});

  // Images states
  const [capturedPhotos, setCapturedPhotos] = useState([]);

  // Current form state
  const [currentForm, setCurrentForm] = useState(1);

  // Check in success
  const [success, setSuccess] = useState(false);

  const [responseData, setResponseData] = useState(null);
  const [successTitle, setSuccessTitle] = useState("");

  const [trailerForm, setTrailerForm] = useState(() => {
    const defaultStructure = {
      value: null,
      notes: "",
    };

    const keys = [
      "chassis",
      "inOutDoors",
      "trailerFloor",
      "tires",
      "sideWalls",
      "frontWall",
      "ceiling",
      "refrigerator",
      "sealCondition",
      "pests",
      "garbage",
      "cleanliness",
      "filter",
      "humidity",
    ];

    const form = {
      trailer_inspection: {},
      seal_tests: {
        view_seal: null,
        verify_seal: null,
        tug_on_seal: null,
        twistTurn_seal: null,
      },
      anomalies: null
    };

    // Assign default structure to keys with value/notes structure
    keys.forEach((key) => {
      form.trailer_inspection[key] = { ...defaultStructure };
    });

    return form;
  });

  const [truckForm, setTruckForm] = useState(() => {
    const defaultStructure = {
      value: null,
      notes: "",
    };

    const keys = [
      "chassis",
      "bumper",
      "engine",
      "tires",
      "cabFloor",
      "ceiling",
      "fuelTank",
      "compartments",
      "airTank",
      "driveShafts",
      "fifthWheel",
      "exhaust",
      "pests",
      "garbage",
      "cleanliness",
    ];

    const form = {
      truck_inspection: {},
      anomalies: null,
    };

    // Create the truckForm object with the same structure for all keys
    keys.forEach((key) => {
      form.truck_inspection[key] = { ...defaultStructure };
    });

    return form;
  });

  const [confirm, setConfirm] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const getTrucks = async () => {
      try {
        const response = await instance.get("assets/get-trucks-out");
        if (response.status === 200) {
          setTruckList(response.data);
        }
      } catch (e) {
        window.alert(e);
      }
    };
    getTrucks();
  }, []);

  const handleCheckInDetails = (setBody, part, value) => {
    setBody((prevFormData) => ({
      ...prevFormData,
      [part]: value,
    }));
  };

  const handleTruckChange = (key, field, value) => {
    if (key === "anomalies") {
      setTruckForm((prevForm) => ({
        ...prevForm,
        anomalies: value
      }));
    } else {
      setTruckForm((prevForm) => ({
        ...prevForm,
        truck_inspection: {
          ...prevForm.truck_inspection,
          [key]: {
            ...prevForm.truck_inspection[key],
            [field]: value,
          },
        },
      }));
    }
  };

  const handleTrailerChange = (key, field, value) => {
    if (key === "seal_tests") {
      setTrailerForm((prevForm) => ({
        ...prevForm,
        seal_tests: {
          ...prevForm.seal_tests,
          [field]: value,
        },
      }));
    } else if (key === "anomalies") {
      setTrailerForm((prevForm) => ({
        ...prevForm,
        anomalies: value,
      }));
    } else {
      setTrailerForm((prevForm) => ({
        ...prevForm,
        trailer_inspection: {
          ...prevForm.trailer_inspection,
          [key]: {
            ...prevForm.trailer_inspection[key],
            [field]: value,
          },
        },
      }));
    }
  };

  const nextForm = () => {
    setCurrentForm(4);
  };

  const backForm = () => {
    setCurrentForm(1);
  };

  const nextCtpatForm = () => {
    if (!carrying && currentForm === 2) {
      setCurrentForm(currentForm + 2);
    } else {
      setCurrentForm(currentForm + 1);
    }
  };

  const prevCtpatForm = () => {
    if (!carrying && currentForm === 4) {
      setCurrentForm(currentForm - 2);
    } else {
      setCurrentForm(currentForm - 1);
    }
  };

  const deletePhoto = (index) => {
    const updatedCapturedPhotos = [...capturedPhotos];
    updatedCapturedPhotos.splice(index, 1);
    setCapturedPhotos(updatedCapturedPhotos);
  };

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (carrying) {
        const formData = new FormData();
        capturedPhotos.forEach((photo, index) => {
          formData.append("photo", photo);
        });

        //Get dimensions of trailer. Order is length, width, height and in feet
        let dimensions = [];
        if (dimensionOption === 1) {
          dimensions = {
            length: parseFloat(53),
            width: parseFloat(8.5),
            height: parseFloat(13.5),
            standard: 53
          }
        } else if (dimensionOption === 2) {
          dimensions = {
            length: parseFloat(48),
            width: parseFloat(8.5),
            height: parseFloat(13.5),
            standard: 48
          }
        } else if (dimensionOption === 3) {
          dimensions = {
            length: parseFloat(40),
            width: parseFloat(8.5),
            height: parseFloat(13.5),
            standard: 40
          }
        } else {
          dimensions = {
            length: parseFloat(customLength),
            width: parseFloat(customWidth),
            height: parseFloat(customHeight),
            standard: null
          }
        }

        const data = {
          trailerBody: {
            ...trailerBody,
            is_loaded: trailerBody.is_loaded === "true" ? true : false,
            is_loaded: trailerBody.is_loaded === "true" ? true : false,
            seal_number:
              trailerBody.is_seal === "true" ? trailerBody.seal_number : null,
            is_seal: trailerBody.is_seal === "true" ? true : false,
            trailer_dimensions: dimensions,
            trailer_type: trailerType,
            customer: trailerBody.is_loaded === "true" ? customer : null,
          },
          truckBody: {
            ...truckBody,
            carrier: carrier !== "Other" ? carrier : otherCarrier,
            reason: "Checking in a trailer",
          },
          f15Trailer: { ...trailerForm },
          f15Truck: { ...truckForm },
          employee_pin: pinNum,
        };

        formData.append("data", JSON.stringify(data));
        const checkInTrailer = await instance.post(
          "assets/check-in-trailer/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/data-form",
            },
          }
        );
        if (checkInTrailer.status === 200) {
          setSuccessTitle(english("forms.type.success"));
          setSuccess(true);
        }
        console.log("checkInTrailer", checkInTrailer);
      } else {
        const formData = new FormData();
        capturedPhotos.forEach((photo, index) => {
          formData.append("photo", photo);
        });
        const data = {
          truckBody: {
            ...truckBody,
            carrier: carrier !== "Other" ? carrier : otherCarrier,
            reason: "Checking in with a truck",
          },
          f15Truck: { ...truckForm },
          employee_pin: pinNum,
        };
        setResponseData(data);

        formData.append("data", JSON.stringify(data));

        const checkInTruck = await instance.post(
          "assets/check-in-truck/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/data-form",
            },
          }
        );

        if (checkInTruck.status === 200) {
          setSuccessTitle(english("forms.type.success"));
          setSuccess(true);
        }
        console.log("checkInTruck", checkInTruck);
      }

      // window.location.href = "/guard-overview";
    } catch (error) {
      setSuccessTitle(error);
      setSuccess(true);
      setLoading(false);
      console.log("error", error);
    }
  };

  const findTrailer = () => {
    let trailer = null;
    if (trailerBody.trailer_number) {
      if (trailerType) {
        trailer = trailerList.find(
          (trailer) => trailer.id === trailerBody.trailer_number
        );
        console.log(trailer);
        return trailer.unit + "-" + trailer.unit_number;
      } else {
        return trailerBody.trailer_number;
      }
    }
  };
  const trailerData = findTrailer();
  return (
    <div>
      {currentForm === 1 ? (
        <CheckIn1stForm
          carrying={carrying}
          setCarrying={setCarrying}
          trailerType={trailerType}
          setTrailerType={setTrailerType}
          atiBool={atiBool}
          setBool={setBool}
          trailerList={trailerList}
          setTrailerList={setTrailerList}
          carrier={carrier}
          setCarrier={setCarrier}
          otherCarrier={otherCarrier}
          setOtherCarrier={setOtherCarrier}
          customer={customer}
          setCustomer={setCustomer}
          customerList={customerList}
          setCustomerList={setCustomerList}
          truckList={truckList}
          setTruckList={setTruckList}
          onClose={onClose}
          english={english}
          nextForm={
            user.location.loc_country === "Mexico" ? nextCtpatForm : nextForm
          }
          generalNotes={generalNotes}
          setGeneralNotes={setGeneralNotes}
          handleCheckInDetails={handleCheckInDetails}
          trailerBody={trailerBody}
          truckBody={truckBody}
          setTrailerBody={setTrailerBody}
          setTruckBody={setTruckBody}
          inOrOut={true}
          dimensionOption={dimensionOption}
          setDimensionOption={setDimensionOption}
          country={country}
          setCountry={setCountry}
          customWidth={customWidth}
          customHeight={customHeight}
          customLength={customLength}
          setCustomWidth={setCustomWidth}
          setCustomLength={setCustomLength}
          setCustomHeight={setCustomHeight}
          countryState={countryState}
          setState={setState}
        />
      ) : null}
      {currentForm === 2 ? (
        <CheckInTruckF15
          nextForm={
            user.location.loc_country === "Mexico" ? nextCtpatForm : nextForm
          }
          backForm={
            user.location.loc_country === "Mexico" ? prevCtpatForm : backForm
          }
          english={english}
          handleTruckChange={handleTruckChange}
          truckForm={truckForm}
          inOrOut={true}
        />
      ) : null}
      {currentForm === 3 ? (
        <CheckInTrailerF15
          backForm={
            user.location.loc_country === "Mexico" ? prevCtpatForm : backForm
          }
          nextForm={
            user.location.loc_country === "Mexico" ? nextCtpatForm : nextForm
          }
          english={english}
          handleTrailerChange={handleTrailerChange}
          trailerForm={trailerForm}
          sealStatus={trailerBody.is_seal}
          inOrOut={true}
        />
      ) : null}
      {currentForm === 4 ? (
        <CheckInPhotos
          english={english}
          backForm={
            user.location.loc_country === "Mexico" ? prevCtpatForm : backForm
          }
          capturedPhotos={capturedPhotos}
          setCapturedPhotos={setCapturedPhotos}
          deletePhoto={deletePhoto}
          submit={submit}
          isLoading={isLoading}
          inOrOut={true}
          confirm={setConfirm}
          user={user}
          pinNum={pinNum}
          setPinNum={setPinNum}
          truckForm={truckForm}
          handleTruckChange={handleTruckChange}
        />
      ) : null}
      {confirm && (
        <CheckoutConfirm
          english={english}
          truck={carrier + "-" + truckBody.truck_number}
          trailer={trailerData}
          confirm={setConfirm}
          submit={submit}
          type="check in"
        />
      )}
      {success && (
        <CheckInSuccess
          title={successTitle}
          message={english("forms.type.vehicle-checked-in")}
          setSuccess={setSuccess}
        />
      )}
    </div>
  );
};
