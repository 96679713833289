export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return phoneNumber;

  switch (phoneNumber.length) {
    case 10: // US local format
      return `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(
        3,
        6
      )}-${phoneNumber.substring(6)}`;
    case 11: // US with country code
      return `+${phoneNumber.substring(0, 1)} (${phoneNumber.substring(
        1,
        4
      )}) ${phoneNumber.substring(4, 7)}-${phoneNumber.substring(7)}`;
    case 12: // International format, e.g., Mexico
      return `+${phoneNumber.substring(0, 2)} (${phoneNumber.substring(
        2,
        5
      )}) ${phoneNumber.substring(5, 8)}-${phoneNumber.substring(8)}`;
    case 13: // International format, e.g., Mexico
      return `+${phoneNumber.substring(0, 2)} (${phoneNumber.substring(
        2,
        5
      )}) ${phoneNumber.substring(5, 8)}-${phoneNumber.substring(8)}`;
    default: // Default to unformatted if it doesn't match expected lengths
      return phoneNumber;
  }
};

export const convertToPST = (datetime) => {
  return new Date(datetime).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone: "America/Los_Angeles",
  });
};