import JSZip from "jszip";
import { populateF15, loadPdfTemplate } from "../Utility/PopulateForms";

export async function Export15Forms(data, setDownloading) {
  try {
    if (data.length === 0) {
      throw new Error("Selecciona minimo un reporte para imprimir.");
    }
    setDownloading(true);
    const pdfFile = "F15-2024.pdf";

    const generatePdfs = async (reports) => {
      if (!reports || reports.length === 0) {
        console.error("No reports available to generate PDFs.");
        return;
      }

      const pdfTemplate = await loadPdfTemplate(pdfFile);
      const zip = new JSZip();

      for (const [index, report] of reports.entries()) {
        const filledPdfBytes = await populateF15(pdfTemplate, report);
        zip.file(`F15_${index + 1}.pdf`, filledPdfBytes);
      }

      const zipBlob = await zip.generateAsync({ type: "blob" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(zipBlob);
      link.download = "Generated_PDFs.zip";
      link.click();
    };

    await generatePdfs(data);
  } catch (e) {
    window.alert(e);
  } finally {
    setDownloading(false);
  }
}
