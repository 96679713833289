/**Prop in parent
 * onChange={(e) => searchQuery(e.target.value)}
 */
import { useTranslation } from "react-i18next";
import { searchQuery } from "../../Interceptor/csrftoken";
/**
 * The SearchBar function is a React component that displays a search input field with a magnifying
 * glass icon and triggers a callback function onChange when the input value changes.
 * @returns The `SearchBar` component is being returned. It consists of a search bar container with a
 * magnifying glass icon and an input field for searching. The `onChange` prop is passed to the input
 * field to handle changes in the search input.
 */
export default function SearchBar() {

  const {t} = useTranslation();
  return (
    <>
      <div className="search-bar-cont">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="magnifying-glass"
          className="svg-inline--fa fa-magnifying-glass "
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            fill="currentColor"
            d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
          ></path>
        </svg>
        <input placeholder={t("yard-tables.search-bar")} onChange={e => searchQuery(e.target.value)}/>
      </div>
    </>
  );
}
