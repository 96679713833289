/**  These are arrays of headers passed as props to the universal table component. Each array
contains specific fields related to trailers, trucks, reservations, guards, dispatch, backlog, etc.
These headers are used to define the columns in tables or data grids where the corresponding data
will be displayed. Headers need to point to the data in the response so the UniversalTable can 
retreive and render the data properly eg. (trailer.trailer_number) not all are like this because
there are cases in the component to handle the data and render them seperatly  */

function isGuardSup(group) {
  console.log(group.name === "Guard Supervisor");
  return group.name === "Guard Supervisor";
}

export const dispatchTrailerHeaders = (user) => [
  "trailer.trailer_number",
  "location.loc_name",
  ...(Array.isArray(user.groups) &&
  user.groups.some((group) => group.name === "Admin")
    ? ["guard.first_name"]
    : []),
  "trailer.is_loaded",
  "trailer.customer",
  "trailer.seal_number",
  "trailer.trailer_condition",
  "trailer.check_in_dt",
  "Duration",
  "trailer.reservation",
];

export const dispatchTruckHeaders = (user) => [
  "location.loc_name",
  ...(Array.isArray(user.groups) &&
  user.groups.some((group) => group.name === "Admin")
    ? ["guard.first_name"]
    : []),
  "truck.truck_number",
  "truck.carrier_name",
  "visitor.first_name",
  "trailer.trailer_condition",
  "truck.check_in_dt",
  "Duration",
];

export const guardTrailerHeaders = (user) => [
  "location.loc_name",
  "trailer.trailer_number",
  ...(Array.isArray(user.groups) &&
  user.groups.some(
    (group) =>
      ["Admin", "Safety", "Guard Supervisor"].includes(group.name) ||
      group.name === "Admin"
  )
    ? ["guard.first_name"]
    : []),
  "trailer.reservation",
  "trailer.is_loaded",
  "trailer.customer",
  "trailer.trailer_condition",
  "trailer.seal_number",
  "trailer.check_in_dt",
  "Duration",
];

export const guardTruckHeaders = (user) => [
  "location.loc_name",
  ...(Array.isArray(user.groups) &&
  user.groups.some(
    (group) =>
      ["Admin", "Safety", "Guard Supervisor"].includes(group.name) ||
      group.name === "Admin"
  )
    ? ["guard.first_name"]
    : []),
  "truck.truck_number",
  "truck.carrier_name",
  "visitor.first_name",
  "truck.check_in_dt",
  "Duration",
  "trailer.trailer_condition",
];

export const guardInvTrailerHeaders = (user) => [
  "location.loc_name",
  ...(Array.isArray(user.groups) &&
  user.groups.some((group) =>
    ["Admin", "Safety", "Guard Supervisor"].includes(group.name)
  )
    ? ["guard.first_name"]
    : []),
  "trailer.trailer_number",
  "trailer.is_loaded",
  "trailer.customer",
  "trailer.seal_number",
  "trailer.trailer_condition",
  "trailer.check_in_dt",
  "Duration",
  "trailer.reservation",
  "Check Out",
];

export const guardInvTruckHeaders = (user) => [
  "location.loc_name",
  ...(Array.isArray(user.groups) &&
  user.groups.some((group) =>
    ["Admin", "Safety", "Guard Supervisor"].includes(group.name)
  )
    ? ["guard.first_name"]
    : []),
  "truck.truck_number",
  "truck.carrier_name",
  "trailer.trailer_condition",
  "visitor.first_name",
  "truck.check_in_dt",
  "Duration",
];

export const mechanicTrailerHeaders = [
  "trailer.trailer_number",
  "trailer.trailer_condition",
  "trailer.check_in_dt",
  "repair_duration_trailer",
  "trailer_condition_notes",
]

export const mechanicTruckHeaders = [
  "truck.truck_number",
  "truck.carrier_name",
  "trailer.trailer_condition",
  "truck.check_in_dt",
  "repair_duration_truck",
  "truck_condition_notes"
]

export const employeeActivityHeaders = [
  "activity.employee_name",
  "activity.trailer",
  "activity.truck",
  "activity.category",
  "activity.description",
  "activity.created_at"
]

export const dispatchInvTrailerHeaders = (user) => [
  "location.loc_name",
  ...(Array.isArray(user.groups) &&
  user.groups.some((group) =>
    ["Admin", "Guard Supervisor"].includes(group.name)
  )
    ? ["guard.first_name"]
    : []),
  "trailer.trailer_number",
  "trailer.is_loaded",
  "trailer.customer",
  "trailer.seal_number",
  "trailer.trailer_condition",
  "trailer.check_in_dt",
  "Duration",
  "trailer.reservation",
  "trailer.reservation.duration"
];

export const dispatchInvTruckHeaders = (user) => [
  "location.loc_name",
  ...(Array.isArray(user.groups) &&
  user.groups.some((group) => group.name === "Admin")
    ? ["guard.first_name"]
    : []),
  "truck.truck_number",
  "truck.carrier_name",
  "trailer.trailer_condition",
  "visitor.first_name",
  "truck.check_in_dt",
  "Duration",
];

export const reservationHeaders = [
  "trailer.trailer_number",
  "trailer.trailer_condition",
  "trailer.check_in_dt",
  "Duration",
  "reservation_status", // referneced case in universal table rather than the data
];

export const backlogTrailerHeaders = [
  "location.loc_name",
  "guard.first_name",
  "trailer.trailer_number",
  "trailer.license_plate",
  "trailer.is_loaded",
  "trailer.customer",
  "trailer.destination",
  "user.first_name",
  "trailer.seal_number",
  "trailer.trailer_condition",
  "trailer.check_in_dt",
  "trailer.reservation",
  "trailer.check_out_dt",
];

export const backlogTruckHeaders = [
  "location.loc_name",
  "guard.first_name",
  "truck.truck_number",
  "truck.carrier_name",
  "trailer.trailer_condition",
  "visitor.first_name",
  "truck.destination",
  "truck.check_in_dt",
  "truck.check_out_dt",
];

export const driverHeaders = [
  "id",
  "is_active",
  "first_name",
  "carrier_name",
  "license_number",
  "personal_phone",
  "work_phone",
  "email",
];