export const CheckInTruckF15 = ({
  nextForm,
  backForm,
  english,
  handleTruckChange,
  truckForm,
  inOrOut
}) => {
  return (
    <div className="questions-cont">
      <h2 className="global-h2 light-black">
        {inOrOut ? english("forms.type.vehicle.check-out.header") : english("forms.type.vehicle.check-out.header")}
      </h2>
      <h4 className="global-h4 gray bottom-space">
        {english("forms.type.f15.truck-subheader")}
      </h4>
      <form onSubmit={nextForm} id="form-truckF15">
        {Object.keys(truckForm.truck_inspection).map(
          (key) =>
            ((key != "truck_anomalies") && (key != "employee_pin")) && (
              <div className="form-group">
                <label className="input-label required">
                  {english(`forms.type.f15.truck.labels.${key}`)}
                </label>
                <div className="input-cont">
                  <fieldset className="fieldsets">
                    <div className="radio-options">
                      <input
                        type="radio"
                        name={key}
                        checked={truckForm.truck_inspection[key].value}
                        onClick={() => handleTruckChange(key, "value", true)}
                        className="radio-input"
                        required
                      />{" "}
                      <label>{english("forms.type.vehicle.placeholders.select-condition.good")}</label>
                    </div>
                    <div className="radio-options">
                      <input
                        type="radio"
                        name={key}
                        checked={
                          truckForm.truck_inspection[key].value === false
                        }
                        onClick={() => handleTruckChange(key, "value", false)}
                        className="radio-input"
                      />
                      <label>{english("forms.type.vehicle.placeholders.select-condition.poor")}</label>
                    </div>
                  </fieldset>
                  <input
                    className="text-input"
                    type="text"
                    placeholder={english(
                      "forms.type.vehicle.input-headers.notes"
                    )}
                    value={truckForm.truck_inspection[key].notes}
                    onChange={(e) =>
                      handleTruckChange(key, "notes", e.target.value)
                    }
                    required={truckForm.truck_inspection[key].value === false}
                  />
                </div>
              </div>
            )
        )}
        <div className="form-group">
          <label className="input-label">{english("forms.type.f15.truck.labels.anomalies")}</label>
          <div className="input-cont">
            <input
              className="text-input"
              placeholder={english("forms.type.f15.truck.labels.anomalies")}
              onChange={(e) =>
                handleTruckChange(
                  "anomalies",
                  "anomalies",
                  e.target.value
                )
              }
              value={truckForm.anomalies}
              type="text"
            />
          </div>
        </div>
        <div className="full-cont">
          <button className="change-pwd-btn" type="submit">
            {english("buttons.continue")}
          </button>
          <button className="cancel-btn" onClick={backForm}>
            {english("buttons.back")}
          </button>
        </div>
      </form>
    </div>
  );
};
