import { useEffect, useState } from "react";
import { Spinner } from "../../LoadingAnimations/LoadingAnimations";

export const AdvancedSearchMechanicReports = ({
  closeForm,
  submitForm,
  english,
}) => {
  const [pinNum, setPinNum] = useState(null);
  const [dateFilter, setDateFilter] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleForm = (e) => {
    e.preventDefault();
    const filters = {
      pinnum: pinNum,
      datefilter: dateFilter,
    };
    try {
      setIsSubmitted(true);
      submitForm(filters);
      setIsSubmitted(false);
      closeForm();
    } catch (error) {
      window.alert(error);
    }
  };

  const handlePinChange = (e) => {
    const value = e.target.value;
    // Allow only numbers and restrict length to 6 digits
    if (/^\d*$/.test(value) && value.length <= 6) {
      setPinNum(value);
    }
  };

  useEffect(() => {}, []);

  return (
    <div className="page-mask page-mask-animation">
      <div style={{ marginLeft: "188px" }} className="form-cont">
        <h2 className="global-h2 light-black">{english("forms.type.advanced-search.header")}</h2>
        <h4 className="global-h4 gray bottom-space">Search all entries</h4>
        <form onSubmit={handleForm}>
          <div className="form-group">
            <label htmlFor="pinNum" className="input-label">
              Pin #
            </label>
            <div className="input-cont">
              <input
                type="password"
                value={pinNum}
                onChange={handlePinChange}
                placeholder={english(
                  "forms.type.vehicle.placeholders.enter-pin"
                )}
                maxLength="3"
                className="text-input"
                required
              />
            </div>
          </div>
          <div className="form-group">
            <label>Date</label>
            <div className="input-cont">
              <input
                type="date"
                className="date-input"
                value={dateFilter}
                onChange={(e) => setDateFilter(e.target.value)}
              />
            </div>
          </div>
          <div className="full-cont">
            {isSubmitted ? (
              <Spinner />
            ) : (
              <button
                type="submit"
                className="change-pwd-btn"
                id="continue-btn"
              >
                Search
              </button>
            )}
            <button className="cancel-btn" onClick={() => closeForm(false)}>
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
